import React from 'react';
// import logo from './logo.svg';
import '../css/App.css';
import 'materialize-css/dist/css/materialize.min.css';
import '../lib/fontawesome-free-5.8.1-web/css/all.min.css'
import WeatherIcons from './Weather_icons';
import M from "materialize-css";
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curTime: new Date(),
            indicators: {},
            data_obj: [],
            nb_news: [],
            current_new: 0,
            done: false
        }
    }


    componentDidMount() {
        // const script = document.createElement("script");
        // script.src = "https://use.typekit.net/foobar.js";
        // script.async = true;
        // document.body.appendChild(script);

        // Auto initialize all the things!
        M.AutoInit();

        this.get_data();

        setInterval(
            () => this.change_view(),
            15000
        );

        setInterval(
            () => this.get_data(),
            600000
        );
        setInterval(
            () => this.get_news(),
            30000
        );
        setInterval(
            () => {
                this.setState({
                    curTime : new Date()
                })
            },1000)
    }

    componentWillUnmount() {

    }

    static autoRoundFloat(value, option=null) {
      // Round the data magnitude
      if (typeof value === 'number') {
        var sign = 1;
        if (value != 0) {
          if (value < 0) {
            sign = -1;
            value = -value;
          }
          if (option) {
            value = value.toFixed(option);
          } else {
            value = parseFloat(value);
            var exponent = Math.floor(Math.log10(value));
            if (exponent >= 1 && exponent < 3){
              value = value.toFixed(Math.abs(exponent-2));
            } else if (exponent < 1){
              value = value.toFixed(Math.abs(exponent-1));
            } else {
              value = App.autoRoundFloat(value / Math.pow(10, exponent), 2) * Math.pow(10, exponent);
              if (exponent > 2) {
                value = Math.round(value);
              }
            }
          }
        }
        return Number(sign*value)
      }
      return 0
    }

    async get_data(){
        let indicators = {};

        // Read data from the current location (URL)
        // Searching for data in URL such as "https://gse.buildsense.fr/?p=7&d=23"
        // to extract the "p" and "d" variables:
        //    "p" => project
        //    "d" => dashboard
        // The target dashboard should be open-access
        function getURIComponent(name) {
          if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search)) {
            return decodeURIComponent(name[1]);
          }
        }

        // project and dashboard ids
        var pId = getURIComponent('p');
        var dId = getURIComponent('d');

        if (pId && dId) {
          const bs_url = 'https://buildsense.fr/';
          // var bs_url = 'http://127.0.0.1:8000/';
          const url = bs_url + 'api/project/' + pId + '/open-dashboard/' + dId + '/';

          const headers = new Headers({ 'content-type': 'application/json', 'Access-Control-Allow-Headers': '*', 'Access-Control-Allow-Origin': '*' });

          await fetch(url, {method: "GET", headers: headers})
              .then(res => res.json())
              .then(result => this.setState({ data_obj: result }));


          // si la requete est ok donc donnée indicators ok
          this.state.data_obj.indicators.forEach(function (element) {
              indicators[element.title] = element ;
          });

          this.setState({
              indicators: indicators,
              nb_news: indicators.news.data.length,
              done: true
          });
          // console.log(this.state.data_obj)
        }

    }

    async get_news(){
        let nb_news = this.state.nb_news-1;
        let current_new = this.state.current_new;
        if (current_new >= 0 && current_new <= nb_news-1) {
            // console.log(current_new);
            this.setState({ current_new: current_new+1 });
        }
        else {
            this.setState({ current_new: 0 })
        }
    }

    async change_view(){
        if ($('#container_1').hasClass('hide')){
            $('#container_1').removeClass('hide');
            $('#container_2').addClass('hide');
        } else {
            $('#container_2').removeClass('hide');
            $('#container_1').addClass('hide');
        }
    }

    render() {

        if (this.state.done===true){
            return (
                <div className="app valign">
                    <div id="container_1" className="center-align">
                        <div className="row">
                            <div className="col s12 container_1_part_1">
                                <div className="col s12">
                                    <div className="title">
                                        <img src={ this.state.indicators.logo.data.replace(/&amp;/g, "&") } alt='logo' className="valign-wrapper logo"/>
                                        <span className="valign-wrapper">{ this.state.indicators.title.data }</span>
                                    </div>
                                </div>

                            </div>
                            <div className="col s12 container_1_part_2 valign-wrapper">
                                <div className="col s4 weather_texts">
                                    <div className="weather_temperature">{ App.autoRoundFloat(this.state.indicators.weather.data.data.temperature) } { this.state.indicators.weather.units.display.label }</div>
                                    <div className="weather_date">{ this.state.done ? this.state.curTime.toLocaleDateString('fr-FR', { weekday: 'short', month: 'short', day: 'numeric' }).toUpperCase() : '' }</div>
                                    <div className="weather_hour">{ this.state.done ? this.state.curTime.toLocaleTimeString('fr-FR', { hour12: false, hour: "numeric", minute: "numeric"}) : '' }</div>
                                </div>
                                <div className="col s4 weather_icon valign">
                                    <WeatherIcons weather_icons={this.state.indicators.weather.data.data.icon} curTime={this.state.curTime}/>
                                    {/*<img src={process.env.PUBLIC_URL + "/imgs/codecanyon-12631845-animated-svg-weather-icons/weathericons/images/rainyIcon.svg"}  alt="test"/>*/}
                                </div>
                                <div className="col s4 schedule center valign">
                                  <div className={(Object.keys(this.state.indicators.opening_hours.data).length === 0)  ? 'hidden' : ''}>
                                      <div className="col s12">La piscine vous accueille de&nbsp;{this.state.indicators.opening_hours.data.start}&nbsp;à&nbsp;{this.state.indicators.opening_hours.data.end}</div>
                                  </div>
                                </div>
                            </div>
                            <div className="container_1_part_3">
                                <div className="col s12">
                                    <p className="news">{ this.state.indicators.news.data[this.state.current_new].content }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="container_2" className="center-align hide">
                        <div className="row">
                            <div className="col s12 valign-wrapper marg_bottom">
                                {/* BASSIN */}
                                <div className="col s2 weather_texts valign">
                                    <i className="fas fa-thermometer-three-quarters big_icon"> </i>
                                </div>
                                <div className="col s5 water valign valign-wrapper">
                                    <div className="col s4">
                                        <i className="fas fa-water vague large_icon"> </i>
                                        <p className="title_indicators">Bassin</p>
                                    </div>
                                    <div className="col s4">
                                        <p className="data_indicators">{ App.autoRoundFloat(this.state.indicators.pool_water_temperature.data.data[0])+' '+this.state.indicators.pool_water_temperature.units.display.label }</p>
                                    </div>


                                </div>
                                {/* FIN BASSIN */}

                                {/* CHANGING ROOM AND HALL AIR TEMPERATURES */}
                                <div className="col s5 valign">
                                    {/* CHANGING ROOM */}
                                    <div className="col s12 valign-wrapper changing_room">
                                        <div className="col s6 indicators_texts valign">
                                            <div className="col s12">
                                                <i className="fas fa-person-booth normal_icon changing_room_color"> </i>
                                            </div>
                                            <div className="col s12">
                                                <p className="title_indicators">Vestiaire</p>
                                            </div>
                                        </div>
                                        <div className="col s6 indicators_texts valign">
                                            <p className="data_indicators">{ App.autoRoundFloat(this.state.indicators.changing_room_temperature.data.data[0])+' '+this.state.indicators.changing_room_temperature.units.display.label }</p>
                                        </div>
                                    </div>
                                    {/* FIN CHANGING ROOM */}

                                    {/* HALL AIR */}
                                    <div className="col s12 valign-wrapper hall_air">
                                        <div className="col s6 indicators_texts valign">
                                            <div className="col s12">
                                                <i className="fas fa-wind vent normal_icon"> </i>
                                            </div>
                                            <div className="col s12">
                                                <p className="title_indicators">Air</p>
                                            </div>
                                        </div>
                                        <div className="col s6 indicators_texts valign">
                                            <p className="data_indicators">{ App.autoRoundFloat(this.state.indicators.hall_air_temperature.data.data[0])+' '+this.state.indicators.hall_air_temperature.units.display.label }</p>
                                        </div>
                                    </div>
                                    {/* FIN HALL AIR */}
                                </div>
                                {/* FIN CHANGING ROOM AND HALL AIR */}
                            </div>
                            <div className="col s12 valign-wrapper">
                                {/* CONSO */}
                                <div className="col s2 valign">
                                    <div className="col s12 text_conso">
                                        <p>Votre piscine consomme :</p>
                                    </div>
                                </div>
                                {/* CONSO EAU */}
                                <div className="col s5 valign conso_eau">
                                    <div className="col s12 valign-wrapper ">
                                        <div className="col s6 valign indicators_texts">
                                            <div className="col s12">
                                                <i className="fas fa-tint eau normal_icon"> </i>
                                            </div>
                                            <div className="col s12">
                                                <p className="title_indicators">Eau</p>
                                            </div>
                                        </div>
                                        <div className="col s6 indicators_texts valign">
                                            <p className="c_eau_data">{  App.autoRoundFloat(this.state.indicators.water_consumption.data.data * this.state.indicators.water_consumption.units.display.conversionFactor) }</p>
                                            <p className="c_eau_unit"> { this.state.indicators.water_consumption.units.display.label }</p>
                                        </div>
                                    </div>
                                </div>
                                {/* FIN DE CONSO EAU */}
                                {/* CONSO ENERGIE */}
                                <div className="col s5 valign ">
                                    <div className="col s12 valign-wrapper conso_energie">
                                        <div className="col s6 valign indicators_texts">
                                            <div className="col s12">
                                                <i className="fas fa-bolt energie normal_icon"> </i>
                                            </div>
                                            <div className="col s12">
                                                <p className="title_indicators">Energie</p>
                                            </div>
                                        </div>
                                        <div className="col s6 valign">
                                            <p className="C_energie_data">{ App.autoRoundFloat(this.state.indicators.global_consumption.data.data * this.state.indicators.global_consumption.units.display.conversionFactor) }</p>
                                            <p className="C_energie_unit">{ this.state.indicators.global_consumption.units.display.label }</p>
                                        </div>
                                    </div>
                                </div>
                                {/* FIN CONSO ENERGIE */}
                                {/*  FIN CONSO  */}
                            </div>
                            <div className="col s12 valign-wrapper">
                                {/* TEXT INFO */}
                                <div className="col s2 valign conso">
                                    <div className="col s12 large_icon">
                                        <i className="fas fa-tachometer-alt tacho"> </i>
                                    </div>
                                </div>
                                <div className="col s5 valign eau">
                                    <div className="col s12 valign-wrapper">
                                        <div className="col s12 valign">
                                            <p className="Comp_energie">{ this.state.indicators.text_info.data }</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s5 valign">
                                    <div className="col s12 valign-wrapper">
                                        <div className="col s12 valign">
                                            <p className="Comp_energie">{ this.state.indicators.text_info2.data }</p>
                                        </div>
                                    </div>
                                </div>
                                {/* FIN TEXT INFO */}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else{
            return (
                <div className="app valign">
                    <div className="loading"></div>
                </div>
            )
        }
    }
}
export default App;
