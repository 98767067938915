import React from "react";
import '../css/Weather_icons.css';

class WeatherIcons extends React.Component {
    get_icon(){
        let weatherIconMapping = {
            'cloudy': this.get_cloudy_moment(),
            'tornado': 'windyCloudyIcon',
            'thunderstorm': 'lightningIcon',
            'clear-day': 'sunnyIcon',
            'clear-night': 'clearNightIcon',
            'partly-cloudy-day': 'partlyCloudyIcon',
            'partly-cloudy-night': 'partlyCloudyNightIcon',
            'cloudy-day': 'mostlyCloudyIcon',
            'cloudy-night': 'partlyCloudyNightIcon',
            'rain': 'showersIcon',
            'sleet': 'rainyIcon',
            'snow': 'snowyIcon',
            'wind': 'windyCloudyIcon',
            'fog': 'fogCloudyIcon',
        };

        let get_translate = weatherIconMapping[this.props.weather_icons];
        if (!get_translate) {
            return this.props.weather_icons
        }else {
            // console.log(get_translate);
            return get_translate
        }

        // if (!get_translate) {
        //     this.current_icon();
        //     return this.props.weather_icons
        //
        // }else {
        //     this.current_icon();
        //     return get_translate
        // }

        // current_icon(){
        //     let test = document.getElementById("test");
        //     if (test){
        //         let toto = test.contentDocument.getElementById('outer');
        //         console.log(toto)
        //         // toto.setAttribute("style",'animation: outer 10s infinite linear; transform-origin: center center;')
        //     }
        //
        //
        //     // var test = document.getElementById('test').contentDocument;
        //     // console.log(test.getElementById("outer"));
        // }

    }
    get_cloudy_moment(){
        const hours = new Date(this.props.curTime).getHours();
        if( hours > 6 && hours < 20 ){
            return "partlyCloudyIcon"
        }else {
            return "partlyCloudyNightIcon";
        }
    }

    render() {
        // return <object id="test" aria-label={this.get_icon()} type="image/svg+xml" data={process.env.PUBLIC_URL + "/imgs/codecanyon-12631845-animated-svg-weather-icons/weathericons/images/"+this.get_icon()+".svg"} />
        // return <iframe height="100%" width="100%" style={{border: 'none', 'border-style': 'none',}} title="test" src={process.env.PUBLIC_URL + "/imgs/codecanyon-12631845-animated-svg-weather-icons/weathericons/images/" + this.get_icon() + ".svg"} />
        return <img alt={this.get_icon()} id={this.get_icon()} src={process.env.PUBLIC_URL + "/img/weather/"+this.get_icon()+".svg"} />
    }
}

export default WeatherIcons;
